<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-hand-water</v-icon>
               <h2>¿Por cuánto tiempo deja las llaves de su lavamanos abiertas cada día?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0">
               <v-text-field
                    v-model="manos"
                    :error-messages="manosErrors"
                    label="Minutos en el lavamanos"
                    outlined
                    @input="setManos($event)"
                    @blur="$v.manos.$touch()"
                    inputmode="numeric"
                ></v-text-field>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q12"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    :disabled="$v.$invalid"
                    to="/q14"
                    >
                    Finalizar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
           <v-col cols="12" sm="8" class="text-justify">
                <v-alert
                    outlined
                    color="info"
                    >
                    <div class="title">
                        Consejos:
                    </div>
                    <div>
                        <strong>Instale aireadores para grifos de bajo flujo en el lavamanos:</strong> Con esto podrá ahorrar galones de agua cada vez que use el grifo. Tome en cuenta que los grifos convencionales de flujo alto dan paso a 11.35 litros por minuto mientras que los grifos de bajo flujo dan paso a 5.67 litros por minuto.
                        <br>
                        <br>
                        <strong>Arregle las llaves con fugas:</strong> El goteo constante es más que simplemente molesto; también representa un enorme desperdicio de agua. Podría estar perdiendo más de 75.70 litros de agua al día de una sola llave. 
                    </div>
                </v-alert>

           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      manos: { required, numeric },
    },

    data() {
        return {
            manos: ''
        }
    },

    mounted() {
        this.manos = this.$store.state.manos
        this.$store.state.progress = 75
        this.goBackIfEmpty()
    },

    computed: {
        manosErrors () {
            const errors = []
            if (!this.$v.manos.$dirty) return errors
            !this.$v.manos.required && errors.push('Minutos en el lavamanos es requerido.')
            !this.$v.manos.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setManos(value) {
            this.$store.state.manos = value
            this.$v.manos.$touch()
        },
        goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }else if(!this.$store.state.comida) {
               this.$router.push('/q5')
           }else if(!this.$store.state.dulces) {
               this.$router.push('/q6')
           }else if(!this.$store.state.verduras) {
               this.$router.push('/q7')
           }else if(!this.$store.state.frutas) {
               this.$router.push('/q8')
           }else if(!this.$store.state.tuberculos) {
               this.$router.push('/q9')
           }else if(!this.$store.state.cafe) {
               this.$router.push('/q10')
           }else if(!this.$store.state.tee) {
               this.$router.push('/q11')
           }else if(!this.$store.state.ducha) {
               this.$router.push('/q12')
           }
       }
    },
}
</script>